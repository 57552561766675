import { Typography } from 'components';
import { ProjectList } from '../components';

function HomePage() {
  return (
    <div className="min-h-[calc(100vh-65px)] p-2 w-full">
      <Typography className="font-lg font-semibold">
        Research Projects
      </Typography>
      <ProjectList />
    </div>
  );
}

export default HomePage;
